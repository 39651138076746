<template>
  <div v-if="has_locked">
    <el-row>
      <el-col style="padding-top:100px;">
        <el-result
          icon="warning"
          title="实验功能已被禁用"
          sub-title="如有疑问请联系我们"
        >
        </el-result>
      </el-col>
    </el-row>
  </div>
  <div class="diy" v-else>
    <iframe :src="diy_url" scrolling="auto" frameborder="0"></iframe>
  </div>
</template>

<script>
import { applyDiy, recordLog } from 'api/experiment/diy'
export default {
  components: {},
  async created() {
    await this.applyUserDiy()
  },
  data() {
    return {
      diy_url: '',
      has_locked: false
    }
  },
  mounted() {
    const that = this;
    window.addEventListener("message", function (event) {
      var data = event.data;
      switch (data) {
        case '51_diy_expt_back': {
          window.location.reload();
        } break;
        case '51_diy_expt_closed': {
          window.location.reload();
        } break;
        case '51_404': {
          window.location.href = "/404";
        } break;
        default: {
          if (typeof data === 'object' && data.hasOwnProperty("diy_action")) {
            const action = data.diy_action;
            const expt_uuid = data.expt_uuid;
            switch (action) {
              case 'detail': {
                that.$router.push(`/diy/detail/${expt_uuid}/?from=/diy/experiment`)
              } break;
              case 'create': {
                recordLog(`添加了开放实验，实验名称为${data.name}。`)
                that.$router.push(`/diy/detail/${expt_uuid}/?from=/diy/experiment`)
              } break;
              case 'copy': {
                recordLog(`复制了开放实验，实验名称为${data.name}。`)
                that.$router.push(`/diy/detail/${expt_uuid}/?from=/diy/experiment`)
              } break;
              case 'delete': {
                recordLog(`删除了开放实验，实验名称为${data.name}。`)
              } break;
            }
          }
        }
      }
    }, '*')
  },
  methods: {
    async applyUserDiy() {
      const that = this;
      await applyDiy().then(result => {
        if (result.succeed) {
          if (result.url) {
            that.diy_url = result.url;
          } else {
            that.has_locked = true;
          }
        } else {
          window.location.href = "/404";
        }
      }).catch(err => {
        if (!err.__CANCEL__) {
          that.$alert(`${err}`, '提示', {
            confirmButtonText: '确定'
          });
        }
      });
    }
  }
  }
</script>

<style lang="scss" scoped>
  .diy {
    display:flex;
    height:100%;
  }
  iframe {
    width:100vw;
    margin:-20px;
    flex:1;
  }
</style>
